

































import gql from 'graphql-tag';
// import Run from '../../../graphql/RunFragment.gql';

import VueNumber from 'vue-number-animation';
Vue.use(VueNumber);

import Vue from 'vue';
export default Vue.extend({
  name: 'PageUploader',
  components: { VueNumber },
  data() {
    return {
      isNotPage: false,
      spirespyGetTotalRunCount: 0,
      spirespyGetLast3Runs: [],
      fromCount: 0,
    };
  },
  apollo: {
    // Query with parameters
    spirespyGetTotalRunCount: {
      // gql query
      query: gql`query {
        spirespyGetTotalRunCount
      }`,
      // Static parameters
      // variables() {
      //   return {
      //     owner: this.itemName,
      //   }
      // },
      // update: (data) => data.totalruns,
    },
  },
  watch: {
    spirespyGetTotalRunCount(): number {
      this.updateFromCount();
      return this.spirespyGetTotalRunCount;
    },
  },
  methods: {
    updateFromCount(): void {
      this.fromCount = this.spirespyGetTotalRunCount;
    },
    victoryOrSlain(bool: boolean): string {
      if (bool) {
        return 'victory';
      }
      return 'slain';
    },
  },

  mounted() {
    setInterval(() => {
      console.log('gettotalruns');
      this.$apollo.queries.spirespyGetTotalRunCount.refetch();
    }, 150000);
  },
});
